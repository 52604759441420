import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/ES/layout'

import pic11 from '../../assets/imgs/landing/p1.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Objectives </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> Objetivos generales </h3>
                        <ul>
                            <li> Entender la diversidad de los límites humanos en el movimiento y en relación con el propio
cuerpo. </li> 
                            <li>  Desarrollar programas y servicios para todas las categorías de personas con o sin discapacidad. </li>
                            <li> Crear un vínculo sólido entre
el deporte y los sectores comprometidos con la inclusión social, además de incrementar el
interés por el deporte entre las personas físicamente inactivas. </li>
                            <li>Maximizar la contribución del deporte en el alcance de los
objetivos de la Estrategia Europa 2020 para un crecimiento inteligente, sostenible e inclusivo.</li> 
                            
                        
                        </ul>

                        <h3> Objetivos específicos
 </h3>
        <ul>


            <li>Comprender las necesidades de jóvenes con o sin discapacidad para su
integración a programas educativos de danza.</li>
            <li> Desarrollar
oportunidades en el ámbito de la danza para personas con discapacidad. </li>
            <li> Fomentar el intercambio de conocimientos y
buenas prácticas en el ámbito de la danza y el deporte adaptada/unificada entre las partes
interesadas. </li>
            <li> Fomentar el diálogo intercultural mediante la
inclusión de personas con distintos orígenes culturales a través del deporte en los tres países
participantes. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Generic